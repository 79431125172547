
import { defineComponent, ref, computed, reactive } from 'vue'
import { forgetpwdForm, resetpwdForm } from '../config/forgetpwd.config'
import { useRouter } from 'vue-router'
import { useCurrentInstance } from '@/utils/public-methods'
import '@/assets/css/index.less'
import hhRequest from '@/service'

export default defineComponent({
  setup() {
    const router = useRouter()
    //邮箱是否验证成功
    const isValidationSuccess = ref(false)

    const { proxy } = useCurrentInstance()

    // 双向绑定的属性由配置文件的field来决定
    // formatData中的属性应该动态来决定
    const formForgetItems = forgetpwdForm?.formItems ?? []
    const formForgetOriginData: any = {}
    for (const item of formForgetItems) {
      formForgetOriginData[item.field] = ''
      // 判断邮箱的有效性
      if (item.field === 'email') {
        item.rules?.forEach((value, index) => {
          if (index === 0) {
            value.message = proxy?.$filters.hh_t('mustEmail')
          } else {
            value.message = proxy?.$filters.hh_t('enterValidEmail')
          }
        })
      }
    }
    const formForgetData = ref(formForgetOriginData)

    const formResetItems = resetpwdForm?.formItems ?? []
    const formResetOriginData: any = {}
    for (const item of formResetItems) {
      formResetOriginData[item.field] = ''
      if (item.field === 'password') {
        item.rules?.forEach((value, index) => {
          if (index === 0) {
            value.message = proxy?.$filters.hh_t('passwordNotEmpty')
          } else {
            value.message = proxy?.$filters.hh_t('passwordRule')
          }
        })
      }
      if (item.field === 'checkPassword') {
        item.rules?.forEach((value, index) => {
          if (index === 0) {
            value.message = proxy?.$filters.hh_t('enterPasswordAgain')
          } else {
            value.validator = passwordtoMatch
            value.message = proxy?.$filters.hh_t('passwordNotMatch')
          }
        })
      }
    }
    const formResetData = ref(formResetOriginData)

    const nextDisabled = computed(() => {
      return !(formForgetData.value.email && formForgetData.value.verification)
    })
    const resetDisabled = computed(() => {
      return !(
        formResetData.value.password && formResetData.value.checkPassword
      )
    })
    function passwordtoMatch(rule: string, value: string) {
      if (value !== formResetData.value.password) {
        return false
      } else {
        return true
      }
    }

    const validationAction = () => {
      isValidationSuccess.value = true
    }

    const accountLoginAction = async () => {
      const data = {
        email: formForgetData.value.email,
        password: formResetData.value.password,
        checkPassword: formResetData.value.checkPassword,
        code: formForgetData.value.verification
      }
      try {
        await hhRequest.post({ url: '/password', data: data })
        await router.replace('/login')
      } catch (error) {
        return error
      }
    }

    return {
      formForgetData,
      formResetData,
      forgetpwdForm,
      resetpwdForm,
      isValidationSuccess,
      validationAction,
      accountLoginAction,
      nextDisabled,
      resetDisabled
    }
  }
})
