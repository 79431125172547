import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-398c21f9")
const _hoisted_1 = { class: "forget-info" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "message" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "message" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hh_form = _resolveComponent("hh-form")!
  const _component_el_button = _resolveComponent("el-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isValidationSuccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_hh_form, _mergeProps(_ctx.resetpwdForm, {
            modelValue: _ctx.formResetData,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.formResetData = $event))
          }), {
            header: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$filters.hh_t('resetPassword')), 1),
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$filters.hh_t('setNewPassword')), 1)
            ]),
            _: 1
          }, 16, ["modelValue"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_hh_form, _mergeProps(_ctx.forgetpwdForm, {
            modelValue: _ctx.formForgetData,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formForgetData = $event)),
            findPwd: true
          }), {
            header: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$filters.hh_t('emailAddressVerification')), 1),
              _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$filters.hh_t('ensure')), 1)
            ]),
            _: 1
          }, 16, ["modelValue"])
        ])),
    (_ctx.isValidationSuccess)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 2,
          type: "primary",
          class: "pulic-button",
          disabled: _ctx.resetDisabled,
          onClick: _ctx.accountLoginAction
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$filters.hh_t('login')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]))
      : (_openBlock(), _createBlock(_component_el_button, {
          key: 3,
          type: "primary",
          class: "pulic-button",
          disabled: _ctx.nextDisabled,
          onClick: _ctx.validationAction
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$filters.hh_t('next')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "onClick"]))
  ]))
}