
import { defineComponent } from 'vue'
import NavLink from './nav-link.vue'

export default defineComponent({
  components: {
    NavLink
  },
  setup() {
    return {}
  }
})
