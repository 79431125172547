
import { defineComponent } from 'vue'
import NavHeader from '@/components/nav-header'
import ForgetPwdVal from './cpns/forgetpwd-validation.vue'

export default defineComponent({
  components: {
    NavHeader,
    ForgetPwdVal
  },
  setup() {
    return {}
  }
})
