import { IForm } from '@/base-ui/form'
import { useCurrentInstance } from '@/utils/public-methods'

export const forgetpwdForm: IForm = {
  formItems: [
    {
      field: 'email',
      type: 'input',
      title: 'email',
      placeholder: 'email',
      rules: [
        {
          required: true,
          message: '',
          trigger: 'blur'
        },
        {
          pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
          message: '',
          trigger: 'blur'
        }
      ],
      width: '440px'
    },
    {
      field: 'verification',
      type: 'verification',
      title: 'verificationCode',
      placeholder: 'verificationCode',
      width: '440px'
    }
  ],
  titleWidth: '440px',
  itemStyle: { margin: '10px 0' },
  colLayout: { span: 24 }
}

export const resetpwdForm: IForm = {
  formItems: [
    {
      field: 'password',
      type: 'password',
      title: 'password',
      placeholder: 'password',
      rules: [
        {
          required: true,
          message: '',
          trigger: 'blur'
        },
        {
          pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/,
          message: '',
          trigger: 'blur'
        }
      ],
      width: '440px'
    },
    {
      field: 'checkPassword',
      type: 'password',
      title: 'confirPassword',
      placeholder: 'confirPassword',
      rules: [
        {
          required: true,
          message: '',
          trigger: 'blur'
        },
        {
          validator: Function,
          message: '',
          trigger: 'blur'
        }
      ],
      width: '440px'
    }
  ],
  titleWidth: '440px',
  itemStyle: { margin: '10px 0' },
  colLayout: { span: 24 }
}
